import Vue from 'vue'
import VueRouter from 'vue-router'
import {Trans} from '@/plugins/Translation'
// import {i18n} from '@/i18n'

const store = require("@/institute.js")
// import {  data } from '@/observable/store'

function load(component) {
    // '@' is aliased to src/components
    return () => import(`@/views/${component}.vue`)
}

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [{
        path: '/:locale',
        component: {
            template: "<keep-alive><router-view></router-view></keep-alive>"
        },
        beforeEnter: Trans.routeMiddleware,
        children:[
            {
                path: '/',
                name: 'dashboard',
                component: load('InputIndex'),
                children:[
                    {
                        path: '',
                        name: 'pos',
                        component: load('sale/Sales'),
                        meta: {
                            mainMenu: 'welcome',
                            title:'bench'
                        },
                    },
                    {
                        path: '',
                        name: 'v3',
                        component: load('sale/SalesV3'),
                        meta: {
                            mainMenu: 'welcome',
                            title:'bench'
                        },
                    },
                    {
                        path: 'signin',
                        name: 'signin',
                        component: load('Signin'),
                        meta: {
                            mainMenu: 'sign_in',
                            title:'Sign In'
                        },
                    },
                    {
                        path: 'tablet',
                        name: 'tablet',
                        component: load('sale/Tablets'),
                        meta: {
                            mainMenu: 'tablet',
                            title:'bench'
                        },
                    },
                    {
                        path: 'sunmit2',
                        name: 'sunmit2',
                        component: load('sale/Sunmi'),
                        meta: {
                            mainMenu: 'sunmi',
                            title:'bench'
                        },
                    },
                    {
                        path: 'maintenance',
                        name: 'maintenance',
                        component: load('sale/Maintenance'),
                        meta: {
                            mainMenu: 'maintenance',
                            title:'Under Maintenace | bench'
                        },
                    }
                ]
            },
            
            // Login Route
            {
                path: 'app',
                name: 'login',
                component: load('HomeIndex'),
                meta: {
                  requiresAuth: false
                },
                children: [
                  {
                    path: '',
                    name: "HomeDashbord",
                    component: load('Dashboard'),
                    meta: {
                      requiresAuth: false,
                      userLogin: true
                    },
                  },
                ]
            },
        ]
    },
    {
        path: '*',
        redirect() {
            return Trans.defaultLocale;
        }
        // component: load('NotFound'),
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to,from,next) => {
    document.title = to.meta.title
    next()
})
router.afterEach((to) => {
    let historyPages = store.default.state.historyPages

    // Remove existing route
    let oldIndex = historyPages.findIndex(i => i.name === to.name)
    if (oldIndex > -1) {
        historyPages.splice(oldIndex, 1)
    }
    // Add route
    store.default.commit("setHistoryPage", to)
})

export default router
